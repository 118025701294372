<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="SearchBar">
          <el-form
            :inline="true"
            class="searchBoxForm"
            size="small"
            label-position="right"
          >
            <el-form-item label="第三方设备名称" class="searchItem">
              <el-input
                v-model="pageParams.equipmentName"
                placeholder="请输入"
                clearable
                class="search"
              ></el-input>
            </el-form-item>
            <el-form-item label="当前状态" class="searchItem">
              <el-select
                v-model="pageParams.bindStatus"
                placeholder="请选择"
                clearable
                class="search"
                @change="search"
              >
                <el-option
                  v-for="(item, index) in optionsStatus"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
           
            <el-form-item label="" class="searchItem">
              <el-button type="primary" @click="search" size="mini"
                >查询</el-button
              >
              <el-button type="warning" @click="restSearch" size="mini"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="contain">
        <el-table
          ref="tableData"
          :data="tableData"
          style="width: 100%; margin-top: 10px"
          border
          :header-cell-style="{
            color: '#333',
            fontFamily: 'MicrosoftYaHeiUI',
            fontSize: '14px',
            fontWeight: 900,
            textAlign: 'left',
            background: '#f8f8f9',
          }"
          height="calc(100vh - 340px)"
          :stripe="true"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            align="center"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="equipmentCode"
            label="第三方设备编码"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="equipmentName"
            label="第三方设备名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="bindStatus"
            label="当前状态"
            show-overflow-tooltip
          >
          <template  slot-scope="{ row }">
              {{row.bindStatus==1?'未绑定':'已绑定'}}
          </template>
          </el-table-column>
          <el-table-column
            prop="pointName"
            label="监测点名称"
            show-overflow-tooltip
          >
          </el-table-column>
          
          <el-table-column label="操作" align="left" width="100">
            <template slot-scope="{ row }">
              <el-button type="text" @click="getDetail(row)" size="mini"
                >{{row.bindStatus==1?'绑定':'解绑'}}</el-button
              >
              
            </template>
          </el-table-column>
        </el-table>
        <div class="total">
          <el-pagination
            background
            layout="total, prev, pager, next,sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageParams.current"
            :page-sizes="[ 30, 50, 100]"
            :page-size="pageParams.size"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      title="绑定"
      :visible.sync="dialogVisible"
      :modal="true"
      :close-on-click-modal="false"
      width="500px"
      @close="close()"
      custom-class="dialog-class"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="130px"
        size="mini"
        style="margin:30px 0px"
      >
      <el-form-item label="监测点名称" class="searchItem">
            <el-select
            v-model="form.monitorPointId"
            placeholder="请选择"
            clearable
            filterable
            class="search"
            >
            <el-option
                v-for="(item, index) in selectList"
                :key="index"
                :label="item.pointName"
                :value="item.monitorPointId"
            ></el-option>
            </el-select>
        </el-form-item>
      </el-form>
       
      <div class="btn">
        <el-button size="mini" @click="close(1)">取消</el-button>
        <el-button  type="primary" size="mini" @click="binding"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageMixins from "@/utils/pageMixins";
import '../style/skin.scss'
import {selectLineRealtimeList,selectMonitorPointList,unBindMonitorPoint,bindMonitorPoint} from '../apis/base'
export default {
  name: "PipeLineList",
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        current: 1,
        size: 100,
      }, //  分页查询参数
      total: null,
      tableData: [], //  表格数据
      form: {},
     
      optionsStatus: [
        {
          value: 2,
          label: '已绑定'
        },
        {
          value: 1,
          label: '未绑定'
        }
      ],
      selectList:[],
     dialogVisible:false
    };
  },
  mounted() {
     this.loadList(this.pageParams);
    // this.getCodeName();
  },
  methods: {
    getDetail(row){
      if(row.bindStatus==1){
        this.form = row;
        this.dialogVisible = true
        selectMonitorPointList({}).then(res=>{
            this.selectList = res.data;
        })
      }else{
        this.$confirm(`确认解绑吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      }).then(() => {
        unBindMonitorPoint({id:row.id,monitorPointId:row.monitorPointId}).then(res=>{
          if(res.code==200){
            this.$message.success('解绑成功！')
            this.pageParams.current = 1;
            this.loadList(this.pageParams)
            this.close()
          }else{
            this.$message.error(res.msg)
          }
          
        })
      });
      }
        
    },
    binding(row){
      this.$confirm(`确认绑定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      }).then(() => {
        bindMonitorPoint({id:this.form.id,monitorPointId:this.form.monitorPointId}).then(res=>{
          if(res.code==200){
            this.$message.success('绑定成功！')
            this.pageParams.current = 1;
            this.loadList(this.pageParams)
            this.close()
          }else{
            this.$message.error(res.msg)
          }
          
        })
      });
    },
    close(){
      this.form = {}
      this.dialogVisible = false;
    },
    loadList(obj) {
      selectLineRealtimeList(obj).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.pageParams = {
        current: 1,
        size: 100,
      };
      this.loadList(this.pageParams);
    },
     handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
  
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool {
  height: 25px;
}
.searchItem {
  margin-bottom: 1%;
  margin-left: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.all {
  background-color: #f3f4f8;
  height: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow: auto;
  height: calc(100vh - 120px);
}
.search {
  width: 215px;
}
.contain {
  height: 690px;
}
.total {
  margin-left: 15px;
  margin-top: 10px;
}
.table {
  overflow: auto;
  height: 520px;
}
.flex-box {
  display: flex;
  justify-content: space-between;
  .el-input {
    width: 190px;
  }
  .el-select {
    width: 190px;
  }
}
.btn {
  text-align: right;
}
</style>